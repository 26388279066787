import _default from "./default";
import _parser from "./parser";
import _xss from "./xss";
var exports = {};

/**
 * xss
 *
 * @author Zongmin Lei<leizongmin@gmail.com>
 */
var DEFAULT = _default;
var parser = _parser;
var FilterXSS = _xss;
/**
 * filter xss function
 *
 * @param {String} html
 * @param {Object} options { whiteList, onTag, onTagAttr, onIgnoreTag, onIgnoreTagAttr, safeAttrValue, escapeHtml }
 * @return {String}
 */

function filterXSS(html, options) {
  var xss = new FilterXSS(options);
  return xss.process(html);
}

exports = exports = filterXSS;
exports.filterXSS = filterXSS;
exports.FilterXSS = FilterXSS;

(function () {
  for (var i in DEFAULT) {
    exports[i] = DEFAULT[i];
  }

  for (var j in parser) {
    exports[j] = parser[j];
  }
})(); // using `xss` on the browser, output `filterXSS` to the globals


if (typeof window !== "undefined") {
  window.filterXSS = exports;
} // using `xss` on the WebWorker, output `filterXSS` to the globals


function isWorkerEnv() {
  return typeof self !== "undefined" && typeof DedicatedWorkerGlobalScope !== "undefined" && self instanceof DedicatedWorkerGlobalScope;
}

if (isWorkerEnv()) {
  self.filterXSS = exports;
}

export default exports;
const _filterXSS = exports.filterXSS,
      _FilterXSS = exports.FilterXSS;
export { _filterXSS as filterXSS, _FilterXSS as FilterXSS };